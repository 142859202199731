import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  CircularProgress,
  Paper,
  Typography,
  Grid,
  Container,
  Tab,
  Tabs,
  Link,
  collapseClasses,
  Tooltip,
  IconButton,
  ClickAwayListener,
  Collapse,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import LikertScale from './../../../components/LikertScale/LikertScale';
import MultipleChoiceSelect from '../../../components/MultipleChoiceSelect/MultipleChoiceSelect';
import { RootStoreType } from '../../../redux/store.type';
import useGetEvaluationQuestions from './../../Accounts/hooks/useGetEvaluationQuestions';
import useGetEvaluationName from '.././../Evaluations/hooks/useGetEvaluationName';
import { EvaluationNameResponse } from './../../Evaluations/reducers/getEvaluationName';
import { EvaluationQuestions } from './../../Accounts/types';
import { useParams } from 'react-router-dom';
import { ErrorMessage } from '.././../../styles/Global.styles';
import usePostEvalutaionSurvey from '../../Evaluations/hooks/usePostEvaluationSurvey';
import {
  CancelButton,
  SaveButton,
} from '.././../Accounts/CreateClient/CreateClient.styles';
import translation from '.././../../locales/en/translation.json';
import { CustomAccountBranding } from './../../Branding/type';
import AssessmentConfirmationModal from './subComponents/AsssessmentConfirmationModal';
import {
  PublicAssessmentOverviewDetails,
  QuestionType,
  AssessmentQuestion,
  AssessmentDefinition,
} from '../../Assessments/type';
import usePostAssessmentEnquiryForm from '../hooks/usePostAssessmentEnquiryForm';
import { AssessmentEnquiryFormRequest, SingleQuestionResponse } from '../type';
import RowRadioButtonsMCGroup from './subComponents/RadioButtonsMCGroup';
import TextEntryInput from './subComponents/TextEntryInput';
import NumericEntryInput from './subComponents/NumericEntryInput';
import { toast } from 'react-toastify';
import ContentCopyIcon from '@mui/icons-material/Link';

interface AssessmentFormProps {
  assessmentDetail: PublicAssessmentOverviewDetails;
  branding: CustomAccountBranding;
  setParentTab: (tabIndex: number) => void;
}

const AssessmentForm: React.FC<AssessmentFormProps> = ({
  assessmentDetail,
  branding,
  setParentTab,
}) => {
  const assessmentId = assessmentDetail.uuid;
  const [formData, setFormData] = useState<SingleQuestionResponse[]>(() => {
    try {
      const savedProgress = localStorage.getItem('assessment-progress-dictionary');
      if (savedProgress) {
        const progressDict = JSON.parse(savedProgress);
        return (progressDict[assessmentId] as SingleQuestionResponse[]) || [];
      }
    } catch (error) {
      console.error('Error loading formData from localStorage:', error);
    }
    return [];
  });

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [areAllRequiredQuestionsAnswered, setAreAllRequiredQuestionsAnswered] =
    useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  const tabContainerRef = useRef<HTMLDivElement | null>(null);
  const questionRefs = useRef<(HTMLDivElement | null)[]>([]);

  const addToRefs = (el: HTMLDivElement | null, questionId: number) => {
    if (el) {
      questionRefs.current[questionId] = el;
    }
  };

  const assessmentDefinition: AssessmentDefinition =
    assessmentDetail.assessment_definition;

  const questionCategories: string[] =
    assessmentDetail?.assessment_definition?.question_categories?.map(
      (category) => category.name,
    ) ?? [];

  const categorizedQuestions =
    assessmentDetail.assessment_definition.question_categories;

  const handleFormChange = (
    questionId: number,
    selectedAnswerId?: number,
    answerText?: string,
  ) => {
    setFormData((prevData) => {
      const existingAnswerIndex = prevData.findIndex(
        (answer) => answer.assessment_question_id === questionId,
      );
      setHighlightedQuestions((prev) => prev.filter((id) => id !== questionId));
      if (existingAnswerIndex >= 0) {
        const updatedAnswers = [...prevData];
        updatedAnswers[existingAnswerIndex] = {
          ...updatedAnswers[existingAnswerIndex],
          selected_answer_id: selectedAnswerId,
          answer: answerText,
          is_default_answer: false,
        };
        return updatedAnswers;
      } else {
        return [
          ...prevData,
          {
            assessment_question_id: questionId,
            selected_answer_id: selectedAnswerId,
            answer: answerText,
            is_default_answer: false,
          },
        ];
      }
    });
  };

  const renderInput = (question: AssessmentQuestion) => {
    switch (question.question_definition.question_type) {
      case QuestionType.MULTIPLE_CHOICE:
        return (
          <RowRadioButtonsMCGroup
            question={question}
            options={[...(question.question_definition.possible_answers || [])]
              .sort((a, b) => (a.display_order ?? 0) - (b.display_order ?? 0))}
            formData={formData}
            onChange={handleFormChange}
          />
        );
      case QuestionType.NUMERIC_RANGE:
      case QuestionType.NUMERIC:
        return (
          <NumericEntryInput
            question={question}
            formData={formData}
            onChange={handleFormChange}
          />
        );
      case QuestionType.TEXT:
        return (
          <TextEntryInput
            question={question}
            formData={formData}
            onChange={handleFormChange}
          />
        );
      default:
        return null;
    }
  };

  const { postSurvey } = usePostAssessmentEnquiryForm();

  const scrollToFirstUnanswered = () => {
    const firstUnansweredRequired = categorizedQuestions[
      currentTab
    ].assessment_questions.find(
      (question) =>
        question.question_definition.is_required &&
        !formData.some(
          (answer) => answer.assessment_question_id === question.id,
        ),
    );

    if (questionRefs.current[firstUnansweredRequired!.id]) {
      questionRefs.current[firstUnansweredRequired!.id]?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    const requiredQuestions = categorizedQuestions[
      currentTab
    ].assessment_questions.filter(
      (question) => question.question_definition.is_required,
    );

    const areAllRequiredAnswered = requiredQuestions.every((question) =>
      formData.some((answer) => answer.assessment_question_id === question.id),
    );

    if (areAllRequiredAnswered) {
      const formattedData: AssessmentEnquiryFormRequest = { answers: formData };
      try {
        setIsLoading(true);
        const isSuccessful = await postSurvey(assessmentId, formattedData);

        if (isSuccessful) {
          // Remove the entry for this AssessmentId from localStorage
          const savedProgress = localStorage.getItem('assessment-progress-dictionary');
          if (savedProgress) {
            const progressDict = JSON.parse(savedProgress);
            delete progressDict[assessmentId];
            localStorage.setItem('assessment-progress-dictionary', JSON.stringify(progressDict));
          }
          setParentTab(2);
        } else {
          toast.error('Submission failed. Please try again.');
        }
      } catch (error) {
        toast.error(
          'An error occurred during form submission. Please try again later.',
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      // Handle case where not all required questions are answered
      setHasSubmitted(true);
      scrollToFirstUnanswered();
    }
  };

  useEffect(() => {
    document.body.style.backgroundColor = '#f9f9f9';
    if (hasSubmitted) {
      const requiredQuestions = categorizedQuestions[
        currentTab
      ].assessment_questions.filter(
        (question) => question.question_definition.is_required,
      );

      //Highlight needed questions with red when trying to continue
      const unansweredRequiredQuestions = requiredQuestions.filter(
        (question) =>
          !formData.some(
            (answer) => answer.assessment_question_id === question.id,
          ),
      );

      // Check if all required questions are answered
      const areAllRequiredAnswered = unansweredRequiredQuestions.length === 0;
      setAreAllRequiredQuestionsAnswered(areAllRequiredAnswered);

      // Call setHighlightedQuestions with each unanswered required question ID
      if (!areAllRequiredAnswered) {
        const questionIdsToHighlight = unansweredRequiredQuestions.map(
          (question) => question.id,
        );
        setHighlightedQuestions(questionIdsToHighlight); // Pass array of IDs
        scrollToFirstUnanswered(); // Scroll to the first unanswered question
      }
    }
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, [formData, categorizedQuestions, hasSubmitted]);

  useEffect(() => {
    if (tabContainerRef.current) {
      tabContainerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [currentTab]);

  useEffect(() => {
    try {
      const savedProgress = localStorage.getItem('assessment-progress-dictionary');
      const progressDict = savedProgress ? JSON.parse(savedProgress) : {};
      // Update the dictionary with the current formData
      progressDict[assessmentId] = formData;
      localStorage.setItem('assessment-progress-dictionary', JSON.stringify(progressDict));
    } catch (error) {
      console.error('Failed to save progress to localStorage:', error);
    }
  }, [formData, assessmentId]);

  const handleNext = () => {
    setHasSubmitted(true);

    const requiredQuestions = categorizedQuestions[
      currentTab
    ].assessment_questions.filter(
      (question) => question.question_definition.is_required,
    );

    const areAllRequiredAnswered = requiredQuestions.every((question) =>
      formData.some((answer) => answer.assessment_question_id === question.id),
    );

    if (areAllRequiredAnswered && currentTab < questionCategories.length - 1) {
      setCurrentTab(currentTab + 1);
      setHasSubmitted(false);
    } else {
      setAreAllRequiredQuestionsAnswered(areAllRequiredAnswered);
      scrollToFirstUnanswered();
    }
  };

  const handleBack = () => {
    if (currentTab > 0) {
      setCurrentTab(currentTab - 1);
    }
  };
  const [highlightedQuestions, setHighlightedQuestions] = useState<number[]>(
    [],
  );

  const setDefaultAnswersForCurrentTab = () => {
    const currentQuestions =
      categorizedQuestions[currentTab].assessment_questions;

    setFormData((prevData) => {
      const updatedData = [...prevData];
      let validDefaults = true;
      setHighlightedQuestions((prev) => []);

      currentQuestions.forEach((question, index) => {
        const existingAnswerIndex = updatedData.findIndex(
          (answer) => answer.assessment_question_id === question.id,
        );
        if (question.default_answer == null && question.question_definition.is_required && existingAnswerIndex < 0) {
          validDefaults = false;
          setHighlightedQuestions((prev) => [...prev, question.id]);
          const questionRef = questionRefs.current[question.id];
          if (questionRef) {
            questionRef.scrollIntoView({
              behavior: 'smooth',
            });
          }
        }

        const defaultAnswer =
          question.question_definition.possible_answers?.find(
            (answer) => answer.id == question.default_answer,
          );
        if (existingAnswerIndex >= 0) {
          // overwrite existing answer with default if it exists
          // updatedData[existingAnswerIndex] = {
          //   ...updatedData[existingAnswerIndex],
          //   selected_answer_id: question.default_answer,
          //   answer: defaultAnswer?.text as string,
          //   is_default_answer: true,
          // };
        } else if (defaultAnswer != undefined) {
          const num = parseFloat(defaultAnswer?.score); // 0
          const defaultScore = !isNaN(num) ? String(num) : undefined; // Convert to string only if it's a valid number

          if (
            question.question_definition.question_type == 'NR' ||
            question.question_definition.question_type == 'N'
          ) {
            // answer field is used on NR and N can be assigned a default score if needed
            updatedData.push({
              assessment_question_id: question.id,
              selected_answer_id: question.default_answer,
              answer: defaultScore,
              is_default_answer: true,
            });
          } else {
            updatedData.push({
              assessment_question_id: question.id,
              selected_answer_id: question.default_answer,
              is_default_answer: true,
            });
          }
        }
      });
      if (validDefaults && currentTab != questionCategories.length - 1) {
        setCurrentTab(currentTab + 1);
      }
      return updatedData;
    });
  };

  const [isSkipAssessmentModalOpen, setSkipAssessmentModalIsOpen] =
    useState(false);

  const handleSkipCategory = () => {
    if (currentTab === questionCategories.length - 1) {
      setDefaultAnswersForCurrentTab();
      setSkipAssessmentModalIsOpen(true);
    } else {
      setDefaultAnswersForCurrentTab();
      setHasSubmitted(false);
    }
  };

  const handleConfirmSubmitFromModal = async () => {
    const formattedData: AssessmentEnquiryFormRequest = { answers: formData };
    await postSurvey(assessmentDetail.uuid, formattedData);
    setSkipAssessmentModalIsOpen(false);
    setParentTab(2);
  };

  const [openTooltipId, setOpenTooltipId] = useState<number | null>(null);

  const handleTooltipClose = () => {
    setOpenTooltipId(null);
  };

  const toggleTooltipOpen = (questionId: number) => {
    if (questionId === openTooltipId) {
      handleTooltipClose();
    } else {
      setOpenTooltipId(questionId);
    }
  };

  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(window.location.href);
    setIsLinkCopied(true);
    setTimeout(() => {
      setIsLinkCopied(false);
    }, 8000); // Reset after 10 seconds
  };

  if (!assessmentDetail) {
    return (
      <Typography
        variant="h6"
        sx={{
          margin: '40px auto',
          color: '#9095a1',
          fontFamily: 'Inter',
          fontWeight: 600,
          textAlign: 'center',
        }}
      >
        {"Evaluation doesn't exist"}
      </Typography>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ backgroundColor: 'white' }}>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'center',
          marginTop: '2%',
          marginBottom: '-4%',
        }}
      >
        {/* Label Text */}
        <Typography
          variant="subtitle1"
          sx={{
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '14px',
            color: '#2E3645',
            marginBottom: '4px',
          }}
        >
          Save this link in a secure place so you can return to where you left off:
        </Typography>
        {/* Link Box with Integrated Copy Button */}
        <Tooltip 
          title={!isLinkCopied ? "Copy to Clipboard" : ''}
          followCursor={true}
          arrow
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 12],
                  }
                }
              ]
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '28px', // Set fixed height to match other elements
              width: '422px',
              backgroundColor: '#F7F7F7',
              border: '1px solid #E0E0E0',
              borderRadius: '4px',
              overflow: 'hidden',
              boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.1)',
            }}
          >
            {/* Link Section */}
            <Box
              sx={{
                // padding: '0px 12px',

                color: '#2E3645',
                overflow: 'hidden',
              }}
            >
              <Link
                onClick={handleCopyClick}
                sx={{
                  color: '#2E3645',
                  textDecoration: 'none',
                  fontFamily: 'Inter',
                  fontSize: '15px',
                  fontWeight: 400,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline',
                  bottom: '5px',
                  position: 'relative',
                  paddingLeft: '2px',
                  cursor: 'pointer',
                }}
              >
                <ContentCopyIcon
                  sx={{
                    display: 'inline',
                    position: 'relative',
                    top: '7px',
                    paddingRight: '2px',
                    fontWeight: 11600,
                  }} />
                {window.location.href}
              </Link>
            </Box>
            {/* Copy Button Integrated Inside the Link Box */}
            <IconButton
              onClick={handleCopyClick}
              size="small"
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: '#FFFFFF',
                backgroundColor: isLinkCopied ? '#16B364' : '#00314B',
                borderLeft: '1px solid #E0E0E0',
                padding: '8px 12px',
                fontSize: '14px',
                borderRadius: '4px',
                transition: 'background-color 0.6s ease',
                '&:hover': {
                  backgroundColor: isLinkCopied ? '#15a55b' : '#002537',
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontFamily: 'Inter',
                  fontWeight: 600,
                  ml: 0.5,
                  transition: 'opacity 0.2s ease',
                }}
              >
                {isLinkCopied ? 'Copied!' : 'Copy Link'}
              </Typography>
            </IconButton>
          </Box>
        </Tooltip>
      </Grid>
      {categorizedQuestions.length > 0 ? (
        isLoading ? (
          <div
            style={{
              position: 'relative',
              top: 0,
              left: 0,
              width: 'auto',
              height: 'auto',
              marginTop: '25%',
              marginBottom: '25%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#FFF',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div ref={tabContainerRef}>            
            <Tabs
              value={currentTab}
              onChange={(event: React.SyntheticEvent, newValue: number) => {
                // Prevent changing the tab by clicking on it
                event.preventDefault();
              }}
              variant="scrollable"
              scrollButtons="auto"
              sx={{ marginTop: '5%' }}
            >
              {questionCategories.map((category, index) => (
                // <Tab key={index} label={category} sx={
                <Tab
                  key={index}
                  label={`Section ${index + 1}`}
                  sx={{
                    backgroundColor: currentTab === index ? '#f9f9f9' : 'white',
                    color: '#053D58', // Optional: change text color based on selection
                    borderRadius: '4px', // Optional: adds rounded corners for better look
                    marginRight: '10px', // Optional: space between tabs
                    cursor: 'default',
                  }}
                />
              ))}
            </Tabs>
            <Grid container>
            
              <Grid
                item
                sx={{
                  backgroundColor: 'white',
                  display: 'flex',
                  paddingBottom: '3%',
                  paddingTop: '3%',
                }}
                sm={12}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: 'Inter',
                    backgroundColor: 'white',
                    paddingLeft: '5%',
                  }}
                >
                  {currentTab + 1}. {questionCategories[currentTab]} -{' '}
                  {categorizedQuestions[currentTab].assessment_questions.length}{' '}
                  Questions
                </Typography>
              </Grid>
              {assessmentDefinition.skippable_question_categories.includes(
                assessmentDetail?.assessment_definition?.question_categories[
                  currentTab
                ].id,
              ) && (
                  <Grid
                    item
                    sx={{
                      backgroundColor: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'end',
                      paddingRight: '2.5%',
                    }}
                    sm={3}
                  >
                    <CancelButton
                      onClick={handleSkipCategory}
                      sx={{
                        display: 'flex',
                        padding: '7px 24px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '1empx',

                        backgroundColor: 'white',
                        color: branding.primary_color,
                        border: '2px solid rgba(0, 0, 0, 0.1)',
                        fontFamily: 'Inter',
                        // fontSize: "24px",
                        fontStyle: 'normal',
                        fontWeight: 600,
                      }}
                    >
                      <span
                        style={{
                          display: 'flex',
                          paddingRight: '7px',
                          alignItems: 'center',
                          justifyContent: 'center',

                          backgroundColor: 'white',
                          color: branding.primary_color,
                          maxWidth: '33px',
                          maxHeight: '22px',
                        }}
                      >
                        <svg
                          width="33"
                          height="22"
                          viewBox="0 0 33 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.5373 9.34121C16.7142 10.1339 16.7142 11.8661 15.5373 12.6588L3.11732 21.0246C1.78887 21.9194 -7.00131e-08 20.9675 0 19.3658L7.31363e-07 2.63419C8.01376e-07 1.03247 1.78887 0.0805801 3.11732 0.975394L15.5373 9.34121Z"
                            fill={branding.primary_color || '#053D58'}
                          />
                          <path
                            d="M31.5373 9.34121C32.7142 10.1339 32.7142 11.8661 31.5373 12.6588L19.1173 21.0246C17.7889 21.9194 16 20.9675 16 19.3658V2.63419C16 1.03247 17.7889 0.0805801 19.1173 0.975394L31.5373 9.34121Z"
                            fill={branding.primary_color || '#053D58'}
                          />
                        </svg>
                      </span>
                      Skip Category
                    </CancelButton>
                    <AssessmentConfirmationModal
                      open={isSkipAssessmentModalOpen}
                      handleClose={() => setSkipAssessmentModalIsOpen(false)}
                      handleConfirm={handleConfirmSubmitFromModal}
                    />
                  </Grid>
                )}
            </Grid>
            <Grid
              sx={{
                backgroundColor: 'white',
              }}
            >
              <hr
                style={{
                  width: '95%',
                  margin: 'auto',
                  backgroundColor: 'white',
                }}
              />
            </Grid>
            {/* To Be Added in V2 */}
          <Typography
            variant="body2"
            sx={{
              color: '#6A6A6A',
              fontFamily: 'Inter',
              backgroundColor: 'white',
              paddingLeft: '5%',
              paddingTop: '2%',
              fontSize: '18px',
            }}
          >
            {categorizedQuestions[currentTab]?.description}
          </Typography>

            <Typography
              variant="body2"
              sx={{
                color: '#6A6A6A',
                fontFamily: 'Inter',
                backgroundColor: 'white',
                paddingLeft: '5%',
                fontSize: "18px",
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '150%',
                paddingTop: '2%',
              }}
            >
              Let&apos;s Start!
            </Typography>

            <form>
              <Grid
                container
                spacing={1}
                sx={{
                  backgroundColor: 'white',
                  paddingLeft: '5%',
                  paddingTop: '2%',
                  marginTop: '0px',
                  width: 'auto',
                }}
              >
                {categorizedQuestions[currentTab]?.assessment_questions?.map(
                  (question, i) => (
                    <Grid
                      item
                      xs={12}
                      key={question.id}
                      sx={{
                        paddingLeft: '0px',
                        marginBottom: '2rem',
                        backgroundColor: highlightedQuestions.includes(
                          question.id,
                        )
                          ? 'rgba(255, 0, 0, 0.1)' // Light red background for better visibility
                          : 'transparent',
                        border: highlightedQuestions.includes(question.id)
                          ? '2px solid red'
                          : 'none',
                      }}
                    >
                      <Paper
                        ref={(el) => addToRefs(el, question.id)}
                        sx={{
                          margin: '0rem',
                          marginBottom: '.5rem',
                          border: '0px',
                          boxShadow: 'none',
                          backgroundColor: highlightedQuestions.includes(
                            question.id,
                          )
                            ? 'rgba(255, 0, 0, 0.00)' // The paper component behaves strange and doesn't inherit the transparency the same
                            : 'transparent',
                        }}
                      >
                        <Box>
                          <Box
                            sx={{
                              fontFamily: 'Inter',
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'flex-start',
                              position: 'relative',
                            }}
                          >
                            <span style={{ width: '30px' }}>{` ${i + 1
                              }. `}</span>
                            <Typography variant="body1" sx={{ flex: 1, position: 'relative' }}>
                              {question.text+' '}                                
                              {question.question_description && (
                                <Box sx={{ display: 'inline' }}>
                                  <Tooltip
                                    title={"Click for more details"}
                                    disableInteractive
                                  >
                                  <Box                                     
                                    sx={{
                                      display: 'inline', 
                                      padding: '1px 5px 1px 1px', 
                                      alignItems: 'center', 
                                      borderRadius: '13px', 
                                      border: "1px solid var(--Vessel-Platform-Stroke-Containers, #E9EDF0)",
                                      background: "#FFF",
                                      boxShadow: "0px 1px 3.9px .4px rgba(26, 26, 26, 0.35);" 
                                    }}
                                    onClick={() => toggleTooltipOpen(question.id)}>
                                    <InfoIcon 
                                      sx={{ 
                                        fontSize: 'medium',
                                        position: 'relative',
                                        left: '3px',
                                        top: '2px',
                                        color: '#9095a1',
                                        cursor: 'pointer',
                                      }}                                  
                                    />
                                    {openTooltipId !== question.id ? (
                                      <ExpandMoreRoundedIcon
                                      sx={{
                                        fontSize: 'medium',
                                        position: 'relative',
                                        color: '#9095a1',
                                        cursor: 'pointer',
                                        left: '3px',
                                        top: '1px',
                                        display:'inline',
                                      }}>
                                      </ExpandMoreRoundedIcon>) : (       
                                      <ExpandLessRoundedIcon
                                      sx={{
                                        fontSize: 'medium',
                                        position: 'relative',
                                        color: '#9095a1',
                                        cursor: 'pointer',
                                        left: '3px',
                                        top: '1px',
                                        display: 'inline',
                                      }}>   
                                      </ExpandLessRoundedIcon>
                                      )
                                    }                          
                                  
                                  </Box>
                                  </Tooltip>
                                  { (
                                      <Collapse in={openTooltipId === question.id} timeout={600}>
                                        <Box
                                          sx={{
                                            position: 'relative',
                                            marginTop: '16px',
                                            marginBottom: '16px',
                                            backgroundColor: '#f5f5f5',
                                            borderRadius: '8px',
                                            padding: '20px',
                                            zIndex: 1000,
                                            width: '100%',
                                            border: '1px solid #e0e0e0',
                                            boxShadow: '0px 4px 12px rgba(0,0,0,0.08)',
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              position: 'absolute',
                                              top: '12px',
                                              right: '12px',
                                              cursor: 'pointer',
                                              width: '24px',
                                              height: '24px',
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              borderRadius: '50%',
                                              '&:hover': {
                                                backgroundColor: 'rgba(0,0,0,0.04)',
                                              },
                                            }}
                                            onClick={handleTooltipClose}
                                          >
                                            ✕
                                          </Box>
                                          <Typography 
                                            sx={{ 
                                              fontSize: '16px',
                                              lineHeight: '1.6',
                                              color: '#2E3645',
                                              fontFamily: 'Inter',
                                              paddingRight: '32px',
                                              fontWeight: 400,
                                              whiteSpace: 'pre-line',
                                            }}
                                          >
                                            {question.question_description}
                                          </Typography>
                                        </Box>
                                      </Collapse>
                                  )}
                                </Box>
                              )}                              
                            </Typography>
                            
                          </Box>
                          {renderInput(question)}
                          {!formData.some(
                            (answer) =>
                              answer.assessment_question_id === question.id,
                          ) &&
                            question.question_definition.is_required &&
                            hasSubmitted && (
                              <ErrorMessage
                                sx={{
                                  paddingLeft: '25px',
                                  fontSize: '16px',
                                  color: 'red',
                                }}
                              >
                                {translation.this_field_is_required}
                              </ErrorMessage>
                            )}
                        </Box>
                      </Paper>
                    </Grid>
                  ),
                )}
              </Grid>
            </form>
          </div>
        )
      ) : (
        <Typography
          variant="h5"
          sx={{
            margin: '40px auto',
            color: '#9095a1',
            fontFamily: 'Inter',
            fontWeight: 900,
            textAlign: 'center',
          }}
        >
          Either you have submitted the response once or this evaluation does
          not contain any questions
        </Typography>
      )}

      {!isLoading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '5%',
            marginBottom: '8%',
          }}
        >
          <SaveButton onClick={handleBack} disabled={currentTab === 0}>
            Back
          </SaveButton>

          {hasSubmitted && !areAllRequiredQuestionsAnswered && (
            <Typography color="red" sx={{ textAlign: 'right' }}>
              Ensure all questions have been answered before continuing.
            </Typography>
          )}
          {currentTab === questionCategories.length - 1 ? (
            <SaveButton onClick={onSubmit}>Submit</SaveButton>
          ) : (
            <SaveButton onClick={handleNext}>Next</SaveButton>
          )}
        </Box>
      )}
    </Container>
  );
};

export default AssessmentForm;
